.sticky {
	position: fixed;
	top: 80%;
	right: 20px;
	z-index: 99;
	margin-top: 25px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
}

ul.social-network li {
    /* display: inline; */
    margin: 0 5px;
}

ul.social-network li i {
    margin:0;
    line-height:50px;
    text-align: center;
}

ul.social-network li a {
	color: white;
	background: var(--code-skyblue);
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

ul.social-network li a:hover i {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

ul.social-network i {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

ul.social-network a.twitter-icon:hover {
    background-color: var(--twitter-dark);
}
ul.social-network a.twitter-icon:hover i {
    color: var(--twitter-blue);
}
ul.social-network a.linkedin-icon:hover {
    background-color: var(--linkedIn-blue);
}
ul.social-network a.instagram-icon:hover {
    background-color: var(--instagram-purple);
}
ul.social-network a.instagram-icon:hover i {
    color: var(--instagram-yellow);
}
ul.social-network a.github-icon:hover {
    background-color: var(--github-gray);
}
ul.social-network a.github-icon:hover i {
    color: var(--black-color);
}
ul.social-network a.spotify-icon:hover {
    background-color: var(--spotify-green);
}
ul.social-network a.envelope-icon:hover {
    background-color: var(--gold-leaf);
}
ul.social-network a.envelope-icon:hover i {
    color: var(--ivory-black);
}