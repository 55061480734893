.navbar {
    font-size: 12px;
  	padding-top: 10px;
  	padding-bottom: 10px;
      /* background: var(--dark-black); */
      /* background: var(--ivory-black); */
      /* background: var(--eerie-black); */
      background: var(--dark-black);
	font-family: 'Lato', serif;
}

/* .bg-light {
    background: var(--dark-black) !important;
    -webkit-box-shadow: 0 6px 6px -6px #cccccc;
    -moz-box-shadow: 0 6px 6px -6px #cccccc;
    box-shadow: 0 6px 6px -6px #cccccc;
} */

/* .navbar-light .navbar-nav .nav-link a {
    color: orange !important;
} */

.nav-link {
    color: var(--cyan-crayola);
}

/* .navbar-dark .navbar-nav .nav-link {
    color: var(--cyan-crayola);
} */

.navbar-dark .navbar-nav .nav-link:hover {
    color: var(--white-color);
}

.navbar-dark .navbar-nav .nav-link:focus {
    color: var(--white-color) !important;
}

.navbar-default .navbar-nav>li>a {
    line-height: 40px;
}

.navbar-brand {
    color: var(--white-color);
}

.navbar-brand i {
    color: var(--cyan-process);
}

@media screen and (max-width: 770px) {
    .navbar-collapse {
        margin-top: 17px;
    }
}
