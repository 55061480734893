body {
    /* background-color: var(--white-color); */
    background-color: var(--eerie-black);
}

/* #intro-section {
	padding: 80px 30px;
	background: url(https://thegadgetflow.com/wp-content/uploads/2018/08/incredible-desk-setup-05-872x1024.jpg) center no-repeat;
	background-size: cover;
	box-shadow: inset 2000px 0 0 0 rgba(255, 255, 255, 0.78);
    color: var(--dark-black) */
  	/*//height: 100vh; // MAKE this responsive by using media queries*/
/* } */

/* #intro-section:after {
    content: '';
    padding-top: 50px;
    vertical-align: middle;
} */

#intro-section {
    /* background-color: var(--eerie-black); */
    /* height: 100vh; */
    /* height: 40vh; */

    /* display: flex; */
    /* align-items: center; */
}

.name-intro {
    color: var(--cyan-crayola);
}

/* #intro-section {
    background-color: white;
} */

#intro-section p {
    font-style: italic;
    color: var(--white-color);
    /*color: $prussianBlue;*/
}

#intro-section .img-circle {
    /*border: 1px solid $ivoryBlack;*/
    border: 1px solid var(--white-color);
}

#intro-photo {
    /* height: 10vh; */
    /* display: flex;
    align-items: center; */
}

.img-circle {
    border-radius: 50%;
}

#scroll-notifier {
    /* position: absolute;
    bottom: 0; */
    top: 95%;
}

/* #expertise-section {
    padding-top: 60px;
    padding-bottom: 100px;
    background: var(--code-skyblue);
    color: var(--white-color);
    text-align: center;
}

#expertise-section h1 {
    padding-bottom: 30px;
    color: var(--prussian-blue);
}

#expertise-section h4 {
    color: var(--prussian-blue);
}

#expertise-section i {
    color: var(--code-skyblue);
}

#expertise-section i.fa-spinner {
    color: var(--prussian-blue);
} */



/* #skills-section { */
    /* text-align: center; */
    /* padding-top: 60px; */
    /* color: var(--white-color);
    background: var(--ivory-black);
} */

/* #skills-section h1 {
    padding-bottom: 30px;
} */

/*#skills-section .skill-category {*/
    /*padding-bottom: 100px;*/
/*}*/

/*@media screen and (max-width: 570px) {*/
    /*#skills-section .skill-category {*/
        /*padding-bottom: 25px;*/
    /*}*/
/*}*/

/* #skills-section .skill-title {
    color: var(--gold-leaf);
    padding-top: 10px;
} */

/*#skills-section .content-right {*/
    /*text-align:left;*/
/*}*/






/* #portfolio-section {
	padding-top: 60px;
  	padding-bottom: 300px;
    color: var(--dark-black)
}

#portfolio-section h1 {
    padding-bottom: 30px;
} */