section {
	padding: 60px 0;
	.section-title {
		// color: var(--cyan-crayola);
		font-weight: 900;
		border-bottom: 2px solid var(--cyan-crayola);
		padding: 10px 0;
		margin-bottom: 25px;
	}
}

#expertise-section {
	// background: var(--dark-skyblue);
	// color: var(--dark-bg-color);
	.section-title {
		// color: var(--cyan-crayola);
		border-color: var(--cyan-crayola);
	}

	h4 {
		font-weight: 900;
	}
}

#skills-section {
	h4 {
		color: var(--dark-skyblue);
		font-weight: 900;
	}
	.skill-group {
		h4 {
			margin-bottom: 25px;
		}
	}
	span.skill {
		font-size: 1.3em;;
		// margin-right: 2em;
		margin-bottom: 0.6em;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;

		i {
			margin-right: 0.8em;
		}
		// margin-right: -15px;
		// margin-left: -15px;
	}
//     // color: var(--white-color);
// 	// background: var(--ivory-black);
// 	background: var(--dark-skyblue);
// 	color: var(--dark-bg-color);
// 	.section-title {
// 		// color: var(--white-color);
// 		// border-color: var(--dark-skyblue);
// 		border-color: var(--white-color);
// 	}
// 	.skill-title {
// 		color: var(--gold-leaf);
// 	}
}

#portfolio-section {
	padding-bottom: 300px;
	.section-title {
		border-color: var(--dark-skyblue);
	}
}
