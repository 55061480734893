@font-face {
	font-family: 'Lato';
	src: url('assets/fonts/Lato-Reg.ttf');
}
@font-face {
	font-family: 'Lato-Bold';
	src: url('assets/fonts/Lato-Bol.ttf');
}
@font-face {
	font-family: 'Quicksand';
	src: url('assets/fonts/Quicksand-Regular.otf');
}
@font-face {
	font-family: 'Quicksand';
	src: url('assets/fonts/Quicksand-Bold.otf');
	font-weight: bold;
}
@font-face {
	font-family: 'Quicksand';
	src: url('assets/fonts/Quicksand-BoldItalic.otf');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: 'Quicksand';
	src: url('assets/fonts/Quicksand-Italic.otf');
	font-style: italic;
}

:root {
    --white-color: #FFFFFF;
	--black-color: #000000;
	/* --eerie-black: #212121; */
	--eerie-black: #161b22;
	/* --gunmetal: #1D2731; */
	--cyan-crayola: #089ECA;

	--gunmetal: #1C2934;
	--blue-sapphire: #0F6A91;
	--cyan-process: #00B3EE;

	--dark-bg-color: #30302F;
    --dark-black: #081016;
    --gold-leaf: #D9B310;
    --off-white: #DEDEDE;
    --prussian-blue: #0B3C5D;
	--dark-skyblue: #1298c5;
	/* --blue-sapphire: #0F6A91; */
	/* --st-patrick-blue: #042275; */

    --twitter-blue: #33ccff;
    --twitter-dark: #1d3748;
    --linkedIn-blue: #007bb7;
    --instagram-purple: #c13584;
    --instagram-yellow: #ffdc80;
    --github-gray: #CCCCCC;
	--spotify-green: #2ebd59;
}

html {
	position: relative;
	min-height: 100%;
}

body {
    font-family: 'Quicksand', 'Lato', serif;
    color: var(--white-color);
    /* background: var(--dark-bg-color); */
    font-size: 14px;
    line-height: 1.42857143;
}

/*body > .container {*/
    /*padding: 60px 15px 0;*/
/*}*/
.main-content {
	color: var(--prussian-blue);
    padding-top: 100px;
    margin-bottom: 215px;
}

@media screen and (max-width: 770px) {
    #content-wrapper {
        padding-top: 70px;
    }
}

.btn-primary {
    color: var(--white-color);
    background-color: var(--prussian-blue);
    border-color: var(--prussian-blue);
}

.btn-primary:disabled {
    color: var(--white-color);
    background-color: var(--prussian-blue);
    border-color: var(--prussian-blue);
}

.btn-primary:hover {
    color: var(--code-skyblue);
    background-color: var(--ivory-black);
    border-color: var(--ivory-black);
}

/**
TODO: Should I move all the css classes below to their own css file for modularity?
 */

/**********************************************************
// Hover icons
//*********************************************************/

.hover-icon {
	display: inline-block;
	line-height: 125px;
	cursor: pointer;
	width: 125px; /* Must match :before class line height */
	height: 125px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	text-decoration: none;
	z-index: 1;
}
.hover-icon.small-icon {
	line-height: 50px;
	width: 50px; /* Must match :before class line height */
	height: 50px;
}

@media screen and (max-width: 570px) {
    .hover-icon {
        width: 80px; /* Must match the corresponding media query's line height */
        height: 80px;
        font-size: 2.4em;
		margin-top: 15px;
		line-height: 85px;
    }
}

/* .center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 5px solid #FFFF00;
	padding: 10px;
} */

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.light-blue {
	background: var(--cyan-crayola) !important;
	color: var(--white-color) !important;
}

.dark-blue {
	background: var(--prussian-blue) !important;
	color: var(--white-color) !important;
}

/**********************************************************
/* sonar hover effects
/**********************************************************/


.sonar-effect {
	background: rgba(255, 255, 255, 0.1);
	-webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
	-moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
	transition: transform ease-out 0.1s, background 0.2s;
}
.sonar-effect:after {
	top: 0;
	left: 0;
	padding: 0;
	z-index: -1;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
	opacity: 0;
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);
}
.sonar-effect:hover {
	background: rgba(255, 255, 255, 0.05);
	-webkit-transform: scale(0.93);
	-moz-transform: scale(0.93);
	-ms-transform: scale(0.93);
	transform: scale(0.93);
	/*//color: #fff;*!*!*/
}
.sonar-effect:hover i {
	color: var(--white-color);
}
.sonar-effect:hover:after {
	-webkit-animation: sonarEffect 1.3s ease-out 75ms;
	-moz-animation: sonarEffect 1.3s ease-out 75ms;
	animation: sonarEffect 1.3s ease-out 75ms;
}
@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		/*//box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);*!*!*/
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--prussian-blue), 0 0 0 10px rgba(255, 255, 255, 0.5);
	}
	100% {
		/*//box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);*/
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--prussian-blue), 0 0 0 10px rgba(255, 255, 255, 0.5);
		-webkit-transform: scale(1.5);
		opacity: 0;
	}
}
@-moz-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--prussian-blue), 0 0 0 10px rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--prussian-blue), 0 0 0 10px rgba(255, 255, 255, 0.5);
		-moz-transform: scale(1.5);
		opacity: 0;
	}
}
@keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--prussian-blue), 0 0 0 10px rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--prussian-blue), 0 0 0 10px rgba(255, 255, 255, 0.5);
		transform: scale(1.5);
		opacity: 0;
	}
}

/**********************************************************
// image hover effects
//********************************************************/

.hovereffect {
	width: 100%;
	/*height: 100%;*/ /* << This was causing blue to extend out toward the bottom */
	float: left;
	overflow: hidden;
	position: relative;
	text-align: center;
	cursor: pointer;
	background: var(--dark-skyblue);
	margin-bottom: 30px;
}

.hovereffect .overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	padding: 50px 20px;
}

.hovereffect img {
	display: block;
	position: relative;
	max-width: none;
	width: calc(100% + 20px);
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-10px,0,0);
	transform: translate3d(-10px,0,0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.hovereffect:hover img {
	opacity: 0.4;
	filter: alpha(opacity=40);
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.hovereffect h2 {
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	position: relative;
	font-size: 17px;
	overflow: hidden;
	padding: 0.5em 0;
	background-color: transparent;
	opacity: 0;
}

.hovereffect h2:after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: var(--white-color);
	content: '';
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(-100%,0,0);
	transform: translate3d(-100%,0,0);
}

.hovereffect:hover h2:after {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.hovereffect a, .hovereffect p {
	color: var(--white-color);
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(100%,0,0);
	transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p, .hovereffect:hover h2 {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

