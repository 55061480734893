.shell {
    color: #53F436;
    font: 100%/140% Consolas, monospace;
    font-size: 12px;
}

.shell-cursor {
    font-weight: 900;
    color: #53F436;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
}

/*************************************************
// terminalGreen = #53F436 //TODO Create a colors file
// blinking caret frames
*************************************************/

@keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #53F436;
    }
}

@-moz-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #53F436;
    }
}

@-webkit-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #53F436;
    }
}

@-ms-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #53F436;
    }
}

@-o-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #53F436;
    }
}
