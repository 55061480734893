body {
	margin: 0;
	overflow-x: hidden;
  }

  #particles-canvas {
	margin: 0;
	padding: 0;
	position: relative;
	/* background: linear-gradient(-135deg, #008b8b, #000); */
	background: var(--dark-black);
	z-index: 1;
	width: 100vw;
	overflow: hidden;
	/* opacity: 0.2; */
  }

  /* #hero-text {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
	  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	text-align: center;
	z-index: 20;
	color: white;
	transform: scale(1.1);
	display: flex;
	align-items: center;
	justify-content: center;
  }

  #hero-text > div {
	background: rgba(0, 0, 0, 0.8);
	padding: 50px;
	font-weight: 500;
	font-size: 3rem;
	box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
  } */

 #landing-page-content {
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
	  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	text-align: center;
	z-index: 20;
	/* color: white; */
	transform: scale(1.1);
	display: flex;
	align-items: center;
	justify-content: center;
  }

  #landing-page-content > div {
	/* background: rgba(0, 0, 0, 0.8); */
	padding: 50px;
	font-weight: 500;
	font-size: 3rem;
	box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
  }