footer {
	background: var(--dark-black);
	color: var(--white-color);
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
  	/* height: 210px; */
}

footer .website-details {
    font-size: 11px;
    padding-top: 30px;
    color: var(--off-white);
}

footer .website-details a {
    color: var(--code-skyblue);
}
/* 
footer .footer-wrapper {
    padding-top: 25px;
} */

footer i {
    color: var(--white-color);
}

/* Everything below was moved to social.css */
footer ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
}

footer ul.social-network li {
    display: inline;
    margin: 0 5px;
}

footer ul.social-network li i {
    margin:0;
    line-height:50px;
    text-align: center;
}

footer ul.social-network li a {
    background: var(--code-skyblue);
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

footer ul.social-network li a:hover i {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

footer ul.social-network i {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

footer ul.social-network a.twitter-icon:hover {
    background-color: var(--twitter-dark);
}
footer ul.social-network a.twitter-icon:hover i {
    color: var(--twitter-blue);
}
footer ul.social-network a.linkedin-icon:hover {
    background-color: var(--linkedIn-blue);
}
footer ul.social-network a.instagram-icon:hover {
    background-color: var(--instagram-purple);
}
footer ul.social-network a.instagram-icon:hover i {
    color: var(--instagram-yellow);
}
footer ul.social-network a.github-icon:hover {
    background-color: var(--github-gray);
}
footer ul.social-network a.github-icon:hover i {
    color: var(--black-color);
}
footer ul.social-network a.spotify-icon:hover {
    background-color: var(--spotify-green);
}
footer ul.social-network a.envelope-icon:hover {
    background-color: var(--gold-leaf);
}
footer ul.social-network a.envelope-icon:hover i {
    color: var(--ivory-black);
}

@media screen and (max-width: 400px) {
    footer ul.social-network li {
        margin: 0;
    }
}